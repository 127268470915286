import React from "react";

import { ProductCategoryContainer, SideBarFilterContainer } from "./styles";

import iconStar from "../../../../Assets/star(white).svg";
import iconClose from "../../../../Assets/close(white).svg";
import iconFilter from "../../../../Assets/filter(white).svg";
import iconSearch from "../../../../Assets/search(white).svg";

import { LIST_CATEGORIES } from "../../../../Api/Categoria";

const SideBarFilter = ({
  isOpen,
  setIsOpen,
  setError,
  setLoading,
  nameProduct,
  setNameProduct,
  categoryId,
  setCategoryId,
  setSelectCategory,
}) => {
  const [categories, setCategories] = React.useState([]);

  const handleListCategories = React.useCallback(async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const { url, options } = LIST_CATEGORIES("1", token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setCategories(responseJson.categorias);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Falha na listagem de categorias. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }, [setError, setLoading]);

  React.useEffect(() => {
    handleListCategories();
  }, [handleListCategories]);

  return (
    <SideBarFilterContainer
      isOpen={isOpen}
      onClick={() => {
        if (isOpen === false) setIsOpen(true);
      }}
    >
      <div>
        <img width={30} height={30} src={iconFilter} alt="icone Filtro" />
        {isOpen && (
          <img
            width={30}
            height={30}
            src={iconClose}
            alt="icone Fechar"
            onClick={() => setIsOpen(false)}
          />
        )}
      </div>
      {isOpen && (
        <div>
          <img
            width={40}
            height={40}
            src={iconSearch}
            alt="icone de Pesquisa"
          />
          <input
            placeholder="Nome"
            type="text"
            label="Nome"
            value={nameProduct}
            onChange={(e) => setNameProduct(e.target.value)}
          />
          <ProductCategoryContainer>
            <ul>
              {categories &&
                categories.map((category) => (
                  <li
                    key={category.id}
                    onClick={() => {
                      setSelectCategory(category.nome);
                      setCategoryId(category.id);
                    }}
                    className={`
                      ${
                        categoryId === category.id && !nameProduct
                          ? "active"
                          : ""
                      } 
                      ${category?.destaque === 1 ? "destaque" : ""}
                    `.trim()}
                  >
                    {category?.destaque ? (
                      <img width={15} src={iconStar} alt="icone de Estrela" />
                    ) : null}
                    {category.nome}
                  </li>
                ))}
            </ul>
          </ProductCategoryContainer>
        </div>
      )}
    </SideBarFilterContainer>
  );
};

export default SideBarFilter;
