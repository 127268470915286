import React from "react";

import { ModalImagesContainer, ModalImagesSubContainer } from "./styles";

import iconBasket from "../../../../../Assets/shopping-basket(black).svg";

import Modal from "../../../../../Components/Modal";
import Button from "../../../../../Components/Button";
import ImageUpload from "../../../../../Components/ImageUpload";

import { IMAGE_PRODUCT, UPDATE_PRODUCT } from "../../../../../Api/Produto";

import {
  CREATE_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
} from "../../../../../Api/ProdutoImagens";

import useFetch from "../../../../../Hook/useFetch";

const ModalImages = ({
  isOpen,
  setIsOpen,
  productData,
  handleFindProduct,
  handleListProducts,
}) => {
  const { loading, request } = useFetch();

  const [error, setError] = React.useState(null);

  const [, setMainImage] = React.useState(null);
  const [mainImagePreview, setMainImagePreview] = React.useState(iconBasket);

  const [secondaryImage, setSecondaryImage] = React.useState(null);
  const [secondaryImagePreview, setSecondaryImagePreview] =
    React.useState(iconBasket);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    setError("");

    setMainImage(null);
    setMainImagePreview(iconBasket);
    setSecondaryImage(null);
    setSecondaryImagePreview(iconBasket);
  }, [
    setIsOpen,
    setError,

    setMainImage,
    setMainImagePreview,
    setSecondaryImage,
    setSecondaryImagePreview,
  ]);

  React.useEffect(() => {
    if (productData) {
      setMainImage(IMAGE_PRODUCT(productData?.imagem_produto));
      setMainImagePreview(IMAGE_PRODUCT(productData?.imagem_produto));
    } else {
      onClose();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [productData]);

  const handleUpdateImage = async (event) => {
    try {
      setError("");

      const file = event.target.files[0];

      if (file != null) {
        const token = window.localStorage.getItem("token");

        const formData = new FormData();
        formData.append("imagem", file);

        const { url, options } = UPDATE_PRODUCT(
          productData.id,
          formData,
          token
        );

        await request(url, options);
      }
    } catch (error) {
      setError("Falha na atualização. Tente novamente mais tarde");
    }
  };

  const handleAddImage = async () => {
    if (!productData?.id || !secondaryImage) {
      setError("Escolha uma imagem");
      return;
    }

    try {
      setError("");

      const token = window.localStorage.getItem("token");

      const formData = new FormData();
      formData.append("imagem", secondaryImage);
      formData.append("produto_id", productData?.id);

      const { url, options } = CREATE_PRODUCT_IMAGE(formData, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        setSecondaryImage(null);
        setSecondaryImagePreview(iconBasket);

        await handleFindProduct(productData?.id);
        await handleListProducts();
      }
    } catch (err) {
      setError("Falha ao adicionar imagem. Tente novamente mais tarde");
    }
  };

  const handleRemoveImage = async (imageId) => {
    if (!imageId) {
      setError("Escolha uma imagem");
      return;
    }

    try {
      setError("");

      const confirmed = window.confirm(
        "Tem certeza de que deseja excluir esta imagem?"
      );

      if (!confirmed) {
        return;
      }

      const token = window.localStorage.getItem("token");
      const { url, options } = DELETE_PRODUCT_IMAGE(imageId, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        await handleFindProduct(productData?.id);
        await handleListProducts();
      }
    } catch (err) {
      setError("Falha ao remover imagem. Tente novamente mais tarde");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={() => {}}>
      <ModalImagesContainer>
        <h1>Imagens do Produto</h1>
        <h2>Imagem Principal</h2>
        <ImageUpload
          label="Escolha uma imagem"
          loading={loading}
          setImage={setMainImage}
          imagePreview={mainImagePreview}
          setImagePreview={setMainImagePreview}
          onChange={(event) => handleUpdateImage(event)}
        />
        <h2>Imagens Secundárias</h2>
        <ImageUpload
          label="Escolha uma imagem e adicione"
          loading={loading}
          setImage={setSecondaryImage}
          imagePreview={secondaryImagePreview}
          setImagePreview={setSecondaryImagePreview}
          onChange={() => {}}
        />
        <Button type="button" disabled={loading} onClick={handleAddImage}>
          Adicionar Imagem
        </Button>
        <ModalImagesSubContainer>
          <h3>Imagens Secundarias</h3>
          {productData?.imagens.length > 0 ? (
            productData?.imagens.map((i, index) => (
              <div key={index}>
                <img
                  key={index}
                  alt="Imagem do Produto"
                  src={IMAGE_PRODUCT(i?.nome)}
                />
                <Button
                  type="button"
                  disabled={loading}
                  title="Apagar item do produto"
                  onClick={() => handleRemoveImage(i?.id)}
                >
                  -
                </Button>
              </div>
            ))
          ) : (
            <h4>Nenhuma imagem cadastrada.</h4>
          )}
          {error && <span>{error}</span>}
        </ModalImagesSubContainer>
      </ModalImagesContainer>
    </Modal>
  );
};

export default ModalImages;
