import React from "react";
import { useNavigate } from "react-router-dom";

import { AuthenticationContainer } from "./styles";

import Input from "../../Components/Input";
import Button from "../../Components/Button";

import { AUTH_USER } from "../../Api/Usuario";

import useUser from "../../Context/User/useUser";

import useForm from "../../Hook/useForm";
import useFetch from "../../Hook/useFetch";

export const Authentication = () => {
  const navigate = useNavigate();

  const email = useForm("email");
  const password = useForm("password");

  const { featchUserToken, setLoginUser } = useUser();

  React.useEffect(() => {
    const checkUserToken = async () => {
      const result = await featchUserToken();
      if (result) navigate("/dados-loja");
    };
    checkUserToken();
  }, [featchUserToken, navigate]);

  const { loading, request } = useFetch();

  const handleUserAuth = async (e) => {
    e.preventDefault();

    if (email.validation() && password.validation()) {
      const data = {
        email: email.value,
        password: password.value,
      };

      const { url, options } = AUTH_USER(data);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        localStorage.setItem("token", response.token);
        navigate("/dados-loja");
        setLoginUser(true);
      }
    }
  };

  return (
    <AuthenticationContainer>
      <form action="submit" onSubmit={handleUserAuth}>
        <h1>Autenticação</h1>
        <p>No momento, autenticação apenas para usuários administradores</p>
        <Input
          required
          type="email"
          disabled={loading}
          {...email}
          label="E-mail"
        />
        <Input
          required
          type="password"
          disabled={loading}
          {...password}
          label="Senha"
        />
        <Button type="submit" title="Entrar" disabled={loading}>
          Entrar
        </Button>
      </form>
    </AuthenticationContainer>
  );
};
