import React from "react";

const validationRules = {
  email: {
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: "Email inválido.",
  },
  password: {
    regex: /^.{4,}$/,
    message: "A senha deve ter no mínimo 4 caracteres.",
  },
  cep: {
    regex: /^\d{5}-\d{3}$/,
    message: "CEP inválido. Formato esperado: 00000-000",
  },
  phone: {
    regex: /^(?:\(\d{2}\)\s?)?\d{4,5}-?\d{4}$/,
    message: "Número de telefone inválido. Formato esperado: (11) 98765-4321",
  },
  dateBirth: {
    regex: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
    message: "Data de nascimento inválida. Formato esperado: dd/mm/yyyy",
  },
  hoursAndMinutes: {
    regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    message: "Horário inválido. Formato esperado: hh:mm",
  },
  price: {
    regex: /^\d+(?:,\d{2})?$/,
    message: "Preço inválido. Formato esperado: NNNN,NN",
  },
};

const useForm = (validationType) => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");

  function validation(value) {
    if (validationType === false) {
      return true;
    } else if (value.length === 0) {
      setError("Campo obrigatório");
      return false;
    } else if (
      validationRules[validationType] &&
      !validationRules[validationType].regex.test(value)
    ) {
      setError(validationRules[validationType].message);
      return false;
    }

    setError("");
    return true;
  }

  function onChange(e) {
    if (error) validation(e.target.value);
    setValue(e.target.value);
  }

  return {
    error,
    value,
    setValue,
    onChange,
    onBlur: () => validation(value),
    validation: () => validation(value),
  };
};

export default useForm;
