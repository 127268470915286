import { API_URL } from "..";

const API_URL_CARRINHO = API_URL + "carrinhos";

export function CREATE_SHOPPINGCART(data, token) {
  return {
    url: `${API_URL_CARRINHO}/cadastrar`,
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        usuario: {
          nome: data?.user.name,
          telefone: data?.user.phone,
        },
        carrinho_itens: data?.shoppingCartProducts,
      }),
    },
  };
}

export function FIND_SHOPPINGCART(shoppingCartId, token) {
  return {
    url: `${API_URL_CARRINHO}/buscar/${shoppingCartId}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function LIST_SHOPPINGCART(data, token) {
  return;
}

export function UPDATE_PRODUCT(shoppingCartId, data, token) {
  return {
    url: `${API_URL_CARRINHO}/atualizar/${shoppingCartId}`,
    options: {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: {
        nome: data?.name ? data?.name : "",
        telefone: data?.phone ? data?.phone : "",
        finalizado_em: data?.finishedIn ? data?.finishedIn : "",
        cancelado_em: data?.cancelledIn ? data?.cancelledIn : "",
      },
    },
  };
}
