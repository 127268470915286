import React from "react";

import { StoreDataContainer } from "./styles";

import ProductData from "./Components/Product/Data";

import CategoryData from "./Components/Category/Data";

import AdData from "./Components/Ad/Data";

export const StoreData = () => {
  const [data, setData] = React.useState("product");

  return (
    <StoreDataContainer>
      <div>
        <strong
          className={data === "product" ? "active" : ""}
          onClick={() => {
            setData("product");
          }}
        >
          Produtos
        </strong>
        <strong
          className={data === "category" ? "active" : ""}
          onClick={() => {
            setData("category");
          }}
        >
          Categorias
        </strong>
        <strong
          className={data === "ad" ? "active" : ""}
          onClick={() => {
            setData("ad");
          }}
        >
          Anúncios
        </strong>
      </div>
      {(() => {
        switch (data) {
          case "product":
            return <ProductData />;
          case "category":
            return <CategoryData />;
          case "ad":
            return <AdData />;
          default:
            return null;
        }
      })()}
    </StoreDataContainer>
  );
};
