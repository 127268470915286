import styled from "styled-components";

export const OrderContainer = styled.div`
  max-width: 500px !important;

  padding: 10px 20px;

  display: flex;
  flex-direction: column;

  gap: 10px;
  align-items: center;

  > button {
    display: flex;
    flex-direction: row;

    gap: 10px;

    align-items: center;
    justify-content: center;
  }

  > button:nth-child(4) {
    border-color: #53ce5d;
    background-color: #53ce5d;
  }
`;

export const OrderStatusContainer = styled.div`
  width: 100%;
  padding: 15px 10px;

  display: flex;
  flex-direction: row;

  gap: 10px;

  align-items: center;
  justify-content: center;

  border-radius: 10px;

  color: white;
  font-size: 1.125rem;

  user-select: none;
`;

export const OrderSummaryContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 10px;

  align-items: center;

  overflow-y: auto;

  h3 {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
  }
`;

export const ItemsShoppingCartContainer = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  flex-direction: row;

  align-items: center;

  padding: 5px 10px;

  border-width: 1px;
  border-style: solid;
  border-color: black;
  border-radius: 8px;

  user-select: none;

  &:hover {
    cursor: pointer;
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.color_03};
    background-color: ${({ theme }) => theme.colors.color_01};
  }

  > img {
    border-radius: 5px;
  }

  > div {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: row;

      p {
        width: 100%;
        text-align: center;
      }
    }
  }
`;
