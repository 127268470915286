import styled from "styled-components";

export const StoreDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: start;

  padding: 0px 10px 10px 10px;

  overflow: hidden !important;

  > div:nth-child(1) {
    width: 100%;
    height: 40px;

    margin-top: 5px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: start;

    strong {
      width: 120px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      text-align: center;
      text-decoration: none;

      border-color: #ddd;
      border-style: solid;
      border-width: 2px 2px 0px 2px;

      background-color: ${({ theme }) => theme.colors.background_02};

      cursor: pointer;

      &:hover {
        width: 125px;
        height: 45px;
      }
    }

    strong:nth-child(1) {
      border-radius: 10px 0px 0px 0px;
    }

    .active {
      width: 125px;
      height: 45px;
      color: ${({ theme }) => theme.colors.text_02};
      border-color: ${({ theme }) => theme.colors.color_01};
      background-color: ${({ theme }) => theme.colors.color_01};
    }
  }

  > div:nth-child(2) {
    width: 100%;
    height: 100%;
  }
`;
