import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ItemsShoppingCartContainer,
  OrderRegistrationContainer,
} from "./styles";

import Input from "../../Components/Input";
import Button from "../../Components/Button";

import useForm from "../../Hook/useForm";
import useFetch from "../../Hook/useFetch";

import useShoppingCart from "../../Context/ShoppingCart/useShoppingCart";

import { CREATE_SHOPPINGCART } from "../../Api/Carrinho";
import { IMAGE_PRODUCT } from "../../Api/Produto";

import { phoneMask } from "../../Helper/mask";
import { NUMERO_WHATSAPP } from "../../Helper/link";

export const OrderRegistration = () => {
  const navigate = useNavigate();

  const { loading, request } = useFetch();

  const { products, productsId, clearShoppingCart } = useShoppingCart();

  const name = useForm("");
  const phone = useForm("phone");

  const [error, setError] = React.useState(null);

  const handleOrderRegistration = async (e) => {
    e.preventDefault();

    setError("");

    if (products.length === 0) {
      setError("Nenhum produto selecionado para fazer o pedido");
      return;
    }

    if (phone.validation() && name.validation()) {
      const data = {
        user: {
          name: name?.value,
          phone: phone?.value,
        },
        shoppingCartProducts: products,
      };

      const { url, options } = CREATE_SHOPPINGCART(data);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        name.setValue("");
        phone.setValue("");

        clearShoppingCart();

        navigate(`/pedido/${response?.carrinho_id}`);

        const phoneNumber = NUMERO_WHATSAPP;

        const message = `Olá! Aqui está o meu pedido:\n\n https://seugift.com.br/pedido/${response?.carrinho_id}`;

        const encodedMessage = encodeURIComponent(message);

        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        window.open(whatsappUrl, "_blank");
      }
    }
  };

  return (
    <OrderRegistrationContainer>
      <form onSubmit={handleOrderRegistration}>
        <h1>Fazer Pedido</h1>
        <Input
          required0
          type="text"
          disabled={loading}
          {...name}
          label="Nome e Sobrenome"
        />
        <Input
          required
          type="text"
          disabled={loading}
          {...phone}
          label="Telefone (Apenas Números)"
          mask={phoneMask}
        />
        <Button type="submit" title="Fazer Pedido" disabled={loading}>
          Fazer Pedido
        </Button>
        {error && <span>{error}</span>}
        <Button
          type="button"
          disabled={loading}
          title="Voltar Catalogo"
          onClick={() => navigate(`/`)}
        >
          Ver mais Produtos
        </Button>
      </form>
      <div>
        <h2>Resumo do Pedido</h2>
        {products.length > 0 ? (
          products.map((p) => {
            return (
              <ItemsShoppingCartContainer
                key={p?.id}
                onClick={() => navigate(`/produto/${p?.id}`)}
              >
                <img
                  width={50}
                  height={50}
                  alt="Imagem Produto"
                  src={IMAGE_PRODUCT(p?.imagem_produto)}
                />
                <div>
                  <div>
                    <p>{p?.nome}</p>
                  </div>
                  <div>
                    <p>
                      Quant. {productsId.filter((id) => id === p?.id).length}
                    </p>
                    <p>{p?.preco}</p>
                  </div>
                </div>
              </ItemsShoppingCartContainer>
            );
          })
        ) : (
          <h3>Nenhum Produto Selecionado</h3>
        )}
      </div>
    </OrderRegistrationContainer>
  );
};
