import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ToggleSwitchInputContainer,
  UserRegistrationContainer,
} from "./styles";

import Input from "../../Components/Input";
import Button from "../../Components/Button";
import ToggleSwitch from "../../Components/ToogleSwitch";

import { CREATE_USER } from "../../Api/Usuario";

import useUser from "../../Context/User/useUser";

import useForm from "../../Hook/useForm";
import useFetch from "../../Hook/useFetch";

import { phoneMask, postalCodeMask } from "../../Helper/mask";

export const UserRegistration = () => {
  const navigate = useNavigate();

  const { loading, request } = useFetch();

  const name = useForm();
  const email = useForm("email");
  const [allowsEmail, setAllowsEmail] = React.useState(true);
  const password = useForm("password");
  const confirmPassword = useForm("password");
  const contactPhone = useForm("phone");
  const [allowsWhatsapp, setAllowsWhatsapp] = React.useState(true);
  const cep = useForm();
  const number = useForm();
  const street = useForm();
  const district = useForm();
  const city = useForm();
  const state = useForm();
  const complement = useForm();

  const onClose = React.useCallback(async () => {
    name.setValue("");
    email.setValue("");
    setAllowsEmail(true);
    password.setValue("");
    confirmPassword.setValue("");
    contactPhone.setValue("");
    setAllowsWhatsapp(true);
    cep.setValue("");
    number.setValue("");
    street.setValue("");
    district.setValue("");
    city.setValue("");
    state.setValue("");
    complement.setValue("");
  }, [
    name,
    email,
    setAllowsEmail,
    password,
    confirmPassword,
    contactPhone,
    setAllowsWhatsapp,
    cep,
    number,
    street,
    district,
    city,
    state,
    complement,
  ]);

  const handleCreateUser = React.useCallback(async () => {
    const body = {
      name: name?.value,
      email: email?.value,
      password: password?.value,
      contactPhone: contactPhone.value,
      allowsEmail: allowsEmail ? "1" : "0",
      allowsWhatsapp: allowsWhatsapp ? "1" : "0",
      cep: cep.value,
      number: number.value,
      street: street.value,
      district: district.value,
      city: city.value,
      state: state.value,
      complement: complement.value,
    };

    const { url, options } = CREATE_USER(body);

    const { response } = await request(url, options);

    if (response.ok === "true") {
      onClose();
      navigate("/");
    }
  });

  React.useEffect(() => {
    const handleCEP = async () => {
      const cepValue = cep.value.trim();

      if (cepValue.length === 9) {
        try {
          const response = await fetch(
            `https://viacep.com.br/ws/${cepValue}/json/`
          );

          const reponseJson = await response.json();

          if (reponseJson?.erro) {
            console.error("CEP não encontrado.");
          } else {
            street.setValue(reponseJson?.logradouro);
            district.setValue(reponseJson?.bairro);
            city.setValue(reponseJson?.localidade);
            state.setValue(reponseJson?.uf);
            complement.setValue(reponseJson?.complemento || "");
          }
        } catch (error) {
          console.error("Erro ao buscar endereço:", error);
        }
      }
    };

    handleCEP();
  }, [cep.value, street, district, city, state, complement]);

  return (
    <UserRegistrationContainer>
      <h1>Cadastro</h1>
      <form action="submit" onSubmit={() => handleCreateUser()}>
        <Input
          required
          type="text"
          disabled={loading}
          {...name}
          label="Nome "
        />
        <Input
          required
          type="text"
          disabled={loading}
          {...contactPhone}
          label="Telefone (Apenas Números)"
          mask={phoneMask}
        />
        <ToggleSwitchInputContainer>
          <label>Receber WhatsApp</label>
          <ToggleSwitch
            isActive={allowsWhatsapp}
            onToggle={() => {
              if (allowsWhatsapp === true && allowsEmail === false)
                setAllowsEmail(true);
              setAllowsWhatsapp(!allowsWhatsapp);
            }}
          />
        </ToggleSwitchInputContainer>
        <Input
          required
          type="email"
          disabled={loading}
          {...email}
          label="E-mail"
        />
        <ToggleSwitchInputContainer>
          <label>Receber E-mails</label>
          <ToggleSwitch
            isActive={allowsEmail}
            onToggle={() => {
              console.log(allowsEmail, allowsWhatsapp);

              if (allowsEmail === true && allowsWhatsapp === false)
                setAllowsWhatsapp(true);
              setAllowsEmail(!allowsEmail);
            }}
          />
        </ToggleSwitchInputContainer>
        <Input
          required
          type="password"
          disabled={loading}
          {...password}
          label="Senha"
        />
        <Input
          required
          type="password"
          disabled={loading}
          {...confirmPassword}
          label="Confirme sua Senha"
        />

        <Input
          required
          type="text"
          disabled={loading}
          {...cep}
          label="CEP (Apenas Números)"
          mask={postalCodeMask}
        />
        <Input
          required
          type="text"
          disabled={loading}
          {...number}
          label="Numero"
        />
        <Input
          required
          type="text"
          disabled={loading}
          {...street}
          label="Rua"
        />
        <Input
          required
          type="text"
          disabled={loading}
          {...district}
          label="Bairro"
        />
        <Input
          required
          type="text"
          disabled={loading}
          {...city}
          label="Cidade"
        />
        <Input
          required
          type="text"
          disabled={loading}
          {...state}
          label="Estado"
        />
        <Input
          required
          type="text"
          disabled={loading}
          {...complement}
          label="Complemento"
        />

        <Button type="submit" title="Entrar" disabled={loading}>
          Cadastrar
        </Button>
      </form>
    </UserRegistrationContainer>
  );
};
