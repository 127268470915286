import React from "react";

import { ProductDataContainer } from "./styles";

import ModalCreate from "../ModalCreate";
import ModalUpdate from "../ModalUpdate";
import ModalItems from "../ModalItems";
import ModalImages from "../ModalImages";
import ModalCategories from "../ModalCategories";

import Table from "../../../../../Components/Table";
import Button from "../../../../../Components/Button";
import Select from "../../../../../Components/Select";
import ToggleSwitch from "../../../../../Components/ToogleSwitch";

import iconUpdate from "../../../../../Assets/update(white).svg";
import iconDelete from "../../../../../Assets/delete(white).svg";
import iconPointer from "../../../../../Assets/pointer(black).svg";

import {
  FIND_PRODUCT,
  LIST_PRODUCTS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "../../../../../Api/Produto";
import { LIST_CATEGORIES } from "../../../../../Api/Categoria";

import useFetch from "../../../../../Hook/useFetch";

const ProductData = () => {
  const { request } = useFetch();

  const [items, setItems] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [nameProduct, setNameProduct] = React.useState("");
  const [categoryId, setCategoryId] = React.useState(null);

  const [productData, setProductData] = React.useState(null);

  const [isOpenModalCreate, setIsOpenModalCreate] = React.useState(false);
  const [isOpenModalUpdate, setIsOpenModalUpdate] = React.useState(false);
  const [isOpenModalItem, setIsOpenModalItem] = React.useState(false);
  const [isOpenModalImage, setIsOpenModalImage] = React.useState(false);
  const [isOpenModalCategory, setIsOpenModalCategory] = React.useState(false);

  const handleListItems = React.useCallback(async () => {
    setProducts([]);

    try {
      setLoading(true);
      setError("");

      const token = window.localStorage.getItem("token");

      const params = {};

      const { url, options } = LIST_PRODUCTS(params, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        setItems(response.produtos);
      }
    } catch (err) {
      setError("Falha ao listar os itens. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, [request]);

  const handleListProducts = React.useCallback(async () => {
    setProducts([]);

    try {
      setLoading(true);
      setError("");

      const token = window.localStorage.getItem("token");

      const params = {
        categoryId,
        nameProduct,
      };

      const { url, options } = LIST_PRODUCTS(params, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        setProducts(response.produtos);
      }
    } catch (err) {
      setError("Falha ao listar os produtos. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, [categoryId, nameProduct, request]);

  const handleListCategories = React.useCallback(async () => {
    try {
      setLoading(true);
      setError("");

      const isVisible = null;
      const token = localStorage.getItem("token");

      const { url, options } = LIST_CATEGORIES(isVisible, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        setCategories(response.categorias);
      }
    } catch (err) {
      setError("Falha ao listar as categorias. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, [request]);

  React.useEffect(() => {
    handleListItems();
    handleListProducts();
    handleListCategories();
  }, [handleListItems, handleListProducts, handleListCategories]);

  const handleFindProduct = React.useCallback(
    async (id) => {
      if (!id) return;

      try {
        const token = window.localStorage.getItem("token");

        const { url, options } = FIND_PRODUCT(id, token);

        const { response } = await request(url, options);

        if (response.ok === "true") {
          setProductData(response.produto);
          return true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [request]
  );

  const handleUpdateProduct = async (id, visivel) => {
    if (!id) return;

    try {
      const token = window.localStorage.getItem("token");

      const formData = new FormData();
      formData.append("visivel", JSON.stringify(visivel));

      const { url, options } = UPDATE_PRODUCT(id, formData, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        let status = visivel === 1 ? 0 : 1;
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === id ? { ...product, visivel: status } : product
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteProduct = async (id) => {
    if (!id) return;

    try {
      const confirmed = window.confirm(
        "Tem certeza de que deseja excluir este produto?"
      );

      if (!confirmed) {
        return;
      }

      const token = window.localStorage.getItem("token");

      const { url, options } = DELETE_PRODUCT(id, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        await handleListProducts();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ProductDataContainer>
      <div>
        <input
          type="text"
          placeholder="Nome do Produto"
          value={nameProduct}
          onChange={(e) => setNameProduct(e.target.value)}
        />
        <Select
          required
          disabled={loading}
          title="Categoria do Produto"
          value={categoryId}
          onChange={(e) => setCategoryId(e.target.value)}
        >
          <option value="">Selecione uma categoria</option>
          {categories.map((category) => (
            <option key={category?.id} value={category?.id}>
              {category?.nome}
            </option>
          ))}
        </Select>
        <Button type="button" onClick={() => setIsOpenModalCreate(true)}>
          Novo Produto
        </Button>
      </div>
      <Table error={error} loading={loading}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Preço</th>
            <th>Nº Itens</th>
            <th>Nº Imagens</th>
            <th>Nº Categorias</th>
            <th>Visualização</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 &&
            products.map((product, index) => (
              <tr key={index}>
                <td>{product?.nome}</td>
                <td>R$ {product?.preco}</td>
                <td
                  className="openModal"
                  onClick={async () => {
                    const result = await handleFindProduct(product?.id);
                    if (result === true) setIsOpenModalItem(true);
                  }}
                >
                  {product?.numero_itens} itens{" "}
                  <img
                    src={iconPointer}
                    width={20}
                    height={20}
                    alt="Editar Itens"
                  />
                </td>
                <td
                  className="openModal"
                  onClick={async () => {
                    const result = await handleFindProduct(product?.id);
                    if (result === true) setIsOpenModalImage(true);
                  }}
                >
                  {product?.numero_imagens + 1} imagens{" "}
                  <img
                    src={iconPointer}
                    width={20}
                    height={20}
                    alt="Editar Imagens"
                  />
                </td>
                <td
                  className="openModal"
                  onClick={async () => {
                    const result = await handleFindProduct(product?.id);
                    if (result === true) setIsOpenModalCategory(true);
                  }}
                >
                  {product?.numero_categorias} categorias{" "}
                  <img
                    src={iconPointer}
                    width={20}
                    height={20}
                    alt="Editar Categorias"
                  />
                </td>
                <td>
                  <ToggleSwitch
                    isActive={product?.visivel === 1 ? true : false}
                    onToggle={() => {
                      handleUpdateProduct(product?.id, product?.visivel);
                    }}
                  />
                </td>
                <td>
                  <Button
                    type="button"
                    title="Editar produto"
                    onClick={async () => {
                      const result = await handleFindProduct(product?.id);
                      if (result === true) setIsOpenModalUpdate(true);
                    }}
                  >
                    <img
                      width="26"
                      height="26"
                      src={iconUpdate}
                      alt="Editar produto"
                    />
                  </Button>
                  <Button
                    type="button"
                    title="Excluir produto"
                    onClick={() => handleDeleteProduct(product?.id)}
                  >
                    <img
                      width="26"
                      height="26"
                      src={iconDelete}
                      alt="Excluir produto"
                    />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <ModalCreate
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        items={items}
        categories={categories}
        handleListProducts={handleListProducts}
      />
      <ModalUpdate
        isOpen={isOpenModalUpdate}
        setIsOpen={setIsOpenModalUpdate}
        productData={productData}
        setProductData={setProductData}
        handleListProducts={handleListProducts}
      />
      <ModalItems
        isOpen={isOpenModalItem}
        setIsOpen={setIsOpenModalItem}
        items={items}
        productData={productData}
        setProductData={setProductData}
        handleFindProduct={handleFindProduct}
        handleListProducts={handleListProducts}
      />
      <ModalImages
        isOpen={isOpenModalImage}
        setIsOpen={setIsOpenModalImage}
        productData={productData}
        handleFindProduct={handleFindProduct}
        handleListProducts={handleListProducts}
      />
      <ModalCategories
        isOpen={isOpenModalCategory}
        setIsOpen={setIsOpenModalCategory}
        categories={categories}
        productData={productData}
        setProductData={setProductData}
        handleFindProduct={handleFindProduct}
        handleListProducts={handleListProducts}
      />
    </ProductDataContainer>
  );
};

export default ProductData;
