import { API_URL } from "..";

const API_URL_USER = API_URL + "usuarios";

export function CREATE_USER(data) {
  return {
    url: API_URL_USER + "/cadastrar",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nome: data?.name,
        email: data?.email,
        senha: data?.password,
        telefone_contato: data?.contactPhone,
        permite_email: data?.allowsEmail,
        permite_whatsapp: data?.allowsWhatsapp,
        cep: data?.cep,
        numero: data?.number,
        rua: data?.street,
        bairro: data?.district,
        cidade: data?.city,
        estado: data?.state,
        complemento: data?.complement,
      }),
    },
  };
}

export function AUTH_USER(data) {
  return {
    url: API_URL_USER + "/autenticar",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data?.email,
        senha: data?.password,
      }),
    },
  };
}

export function FIND_USER(token) {
  return {
    url: `${API_URL_USER}/buscar`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}
