import styled from "styled-components";

export const CategoryDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: start;
  justify-content: center;

  > div:nth-child(1) {
    width: 100%;
    height: fit-content;

    padding: 5px 12.5px;

    display: flex;
    align-items: center;
    justify-content: start;

    background-color: ${({ theme }) => theme.colors.color_01};

    button {
      background-color: ${({ theme }) => theme.colors.color_02};
      &:hover {
        background-color: ${({ theme }) => theme.colors.color_03};
      }
    }
  }
`;
