import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  ImageContainerForSmallerScreen,
  WarningContainer,
  ProductContainer,
  ProductSubContainer,
  ProductInformationContainer,
  ProductDescriptionContainer,
  OtherProductsContainer,
} from "./styles";

import iconWhatsApp from "../../Assets/whatsapp.png";
import iconAttention from "../../Assets/attention(black).svg";

import Button from "../../Components/Button";
import Loading from "../../Components/Loading";
import CardProduct from "../../Components/CardProduct";

import ImageGallery from "./Components/ImageGallery";
import SideBarShoppingCart from "../../Components/SideBarShoppingCart";

import {
  CATALOG_PRODUCTS,
  FIND_PRODUCT,
  IMAGE_PRODUCT,
} from "../../Api/Produto";

import { NUMERO_WHATSAPP } from "../../Helper/link";
import useShoppingCart from "../../Context/ShoppingCart/useShoppingCart";

export const Product = () => {
  const navigate = useNavigate();
  const { id: productId } = useParams();

  const [product, setProduct] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [products, setProducts] = React.useState([]);

  const { addProductToCart } = useShoppingCart();

  const whatsappRequest = () => {
    const message = `Olá! Gostaria de fazer o pedido uma ${product?.nome}.`;
    const encodedMessage = encodeURIComponent(message);
    const linkWhatsApp = `https://wa.me/${NUMERO_WHATSAPP}?text=${encodedMessage}`;
    window.open(linkWhatsApp, "_blank");
  };

  const fetchProductList = React.useCallback(async (categoryId) => {
    try {
      setLoading(true);

      const { url, options } = CATALOG_PRODUCTS("", "", "1", categoryId);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setProducts(responseJson.produtos);
      }
    } catch (err) {
      console.log("Falha no servidor. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchProduct = React.useCallback(async () => {
    try {
      setLoading(true);

      const { url, options } = FIND_PRODUCT(productId, "");

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson?.ok === "true") {
        setProduct(responseJson?.produto);
        await fetchProductList(responseJson?.produto?.id_categoria);
      }
    } catch (err) {
      navigate("/");
    } finally {
      setLoading(false);
    }
  }, [productId, fetchProductList, navigate]);

  React.useEffect(() => {
    if (productId) fetchProduct();
  }, [productId, fetchProduct]);

  const renderImageContent = () => {
    const minWidth = 800;

    const imageList = [
      { nome: product?.imagem_produto || "" },
      ...(product?.imagens || []),
    ];

    if (window.innerWidth >= minWidth) {
      return <ImageGallery images={imageList} />;
    } else {
      return (
        <ImageContainerForSmallerScreen>
          {imageList.map((image, index) => (
            <img
              key={index}
              src={IMAGE_PRODUCT(image.nome)}
              alt="Imagem do Produto"
            />
          ))}
        </ImageContainerForSmallerScreen>
      );
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <ProductContainer>
        <WarningContainer>
          <p>
            <img src={iconAttention} alt="Icone de atenção" height={25} />
            No momento, os pedidos estão sendo realizados pelo WhatsApp ou
            diretamente em nossa loja.
          </p>
          {product?.id_categoria === 1 && (
            <p>
              <img src={iconAttention} alt="Icone de atenção" height={25} />
              Temos uma grande variedade de Cestas, personalizamos o conteúdo
              conforme a necessidade do cliente! Em função disso, os preços
              podem variar bastante, consulte-nos.
            </p>
          )}
        </WarningContainer>
        <ProductSubContainer>
          {renderImageContent()}
          <ProductInformationContainer>
            <div>
              <strong>{product?.nome}</strong>
            </div>
            <div>
              <p>Preço: </p>
              <strong>
                {product?.preco | (product?.preco !== "0,00")
                  ? `R$ ${product?.preco}`
                  : `Consulte-nos`}
              </strong>
            </div>
            {product?.categorias?.length > 0 ? (
              <div>
                <p>Categorias do Produto</p>
                <ul>
                  {product?.categorias.map((category) => (
                    <li key={category.id}>{category.nome}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <div></div>
            )}
            {product?.itens?.length > 0 ? (
              <div>
                <p>Itens do Produto</p>
                <ul>
                  {product?.itens.map((item) => (
                    <li key={item.id}>{item.nome}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <Button
                className="whatsapp"
                type="button"
                onClick={whatsappRequest}
              >
                Comprar
                <img height={25} alt="Icone do WhatsApp" src={iconWhatsApp} />
              </Button>
              <Button
                type="button"
                alt="Adicionar ao Carrinho"
                onClick={() => addProductToCart(product.id)}
              >
                Adicionar ao Carrinho
              </Button>
              <Button type="button" onClick={() => navigate(-1)}>
                Voltar
              </Button>
            </div>
          </ProductInformationContainer>
        </ProductSubContainer>
        <ProductDescriptionContainer>
          <h2>Descrição do Produto</h2>
          <p>
            {product?.descricao
              ? product?.descricao
              : "Descrição não registradas"}
          </p>
        </ProductDescriptionContainer>
        <OtherProductsContainer>
          <h2>Outros Produtos Similares</h2>
          <div>
            {products.length > 0
              ? products.map((p) => <CardProduct key={p?.id} product={p} />)
              : null}
          </div>
        </OtherProductsContainer>
        <SideBarShoppingCart />
      </ProductContainer>
    );
  }
};
