import React from "react";
import PropTypes from "prop-types";

import { InputContainer } from "./styles";

import iconeVisible from "./../../Assets/visible(white).svg";
import iconeNotVisible from "./../../Assets/not-visible(white).svg";

function Input({
  type,
  label,
  required,
  value,
  onChange,
  onBlur,
  error,
  mask,
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const inputType = type === "password" && showPassword ? "text" : type;

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (mask) newValue = mask(newValue);
    onChange({ ...e, target: { ...e.target, value: newValue } });
  };

  return (
    <InputContainer>
      <input
        type={inputType}
        required={required}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        className={value || value === 0 || type === "date" ? "has-value" : ""}
      />
      {type === "password" && (
        <button type="button" onClick={toggleShowPassword}>
          {showPassword ? (
            <img src={iconeVisible} alt="Icone Visivel" height={20} />
          ) : (
            <img src={iconeNotVisible} alt="Icone Não Visivel" height={20} />
          )}
        </button>
      )}
      <label className={value ? "shrink" : ""}>{label}</label>
      {error && <span>{error}</span>}
    </InputContainer>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  mask: PropTypes.func,
};

export default Input;
