import styled from "styled-components";

export const ProductContainer = styled.div`
  width: 100%;

  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 27px;
  align-items: center;
`;

export const WarningContainer = styled.div`
  width: 95%;
  padding: 20px 40px;

  display: flex;
  flex-direction: column;

  gap: 10px;
  align-items: center;
  justify-content: center;

  text-align: center;

  background-color: rgba(168, 159, 59, 0.5);

  border-radius: 10px;
  border-color: ${({ theme }) => theme.colors.border_03};

  p {
    display: flex;
    flex-direction: row;

    gap: 3px;
    align-items: center;
    justify-content: center;
  }
`;

export const ProductSubContainer = styled.div`
  max-width: 1400px;
  width: 100%;

  margin: 0px auto;

  display: flex;
  flex-direction: row;

  gap: 10px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const ImageContainerForSmallerScreen = styled.div`
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;

  gap: 5px;

  > img {
    width: 95%;
    margin: 0px auto;
    border-radius: 10px;
  }
`;

export const ProductInformationContainer = styled.div`
  min-width: 300px;
  max-width: 400px;
  width: 100%;

  min-height: 600px;
  max-height: 600px;
  height: 100%;

  display: flex;
  flex-direction: column;

  gap: 10px;
  align-items: start;

  .whatsapp {
    display: flex;
    flex-direction: row;

    gap: 10px;
    align-items: center;
    justify-content: center;

    border-color: #5bbf5f;
    background-color: #53ce5d;

    &:hover {
      background-color: #5bbf5f;
    }
  }

  > div {
    width: 100%;

    display: flex;
    flex-direction: column;

    align-items: start;

    > * {
      width: 100%;
    }

    > strong {
      font-size: 2rem;
      color: ${({ theme }) => theme.colors.color_02};
    }

    > p {
      font-size: 1.75rem;
      font-weight: bold;
    }

    > ul {
      max-height: 130px;

      overflow-y: auto;
      padding: 10px 20px;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.colors.background_03};

      list-style-type: none;
      li {
        text-align: start;
        font-size: 1.125rem;
      }
    }
  }

  > div:nth-child(1),
  > div:nth-child(2) {
    flex: 0.5;
    justify-content: center;
  }

  > div:nth-child(3) {
    flex: 1;
    justify-content: center;
  }

  > div:nth-child(4) {
    flex: 1;
    justify-content: center;
  }

  > div:nth-child(5) {
    flex: 0.3;
    gap: 5px;
    justify-content: space-between;
  }
`;

export const ProductDescriptionContainer = styled.div`
  width: 95%;
  padding: 25px 40px;

  display: flex;
  flex-direction: column;

  gap: 20px;
  align-items: center;

  text-align: center;

  border-radius: 10px;
  border-color: ${({ theme }) => theme.colors.border_03};

  background-color: ${({ theme }) => theme.colors.background_03};
`;

export const OtherProductsContainer = styled.div`
  width: 100%;
  height: fit-content;

  > h2 {
    margin: 0px 0px 10px 10px;
  }

  > div {
    width: 100%;
    height: 350px;

    padding: 5px 0px;

    display: flex;
    flex-direction: row;

    gap: 10px;
    align-items: start;
    justify-content: start;

    overflow-x: auto;
    overflow-y: hidden;

    background-color: ${({ theme }) => theme.colors.background_03};

    > div {
      min-width: 280px;
      margin: 0;
    }
  }
`;
