import styled from "styled-components";

export const UserRegistrationContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 20px;

  display: flex;
  flex-direction: column;

  gap: 5px;
  align-self: center;

  @media (min-width: 525px) {
    justify-content: center;
  }

  @media (max-width: 525px) {
    justify-content: start;
  }

  h1 {
    text-align: center;
  }

  form {
    max-width: 800px;
    width: 100%;

    margin: 0 auto;

    display: grid;

    gap: 15px;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: 1fr 1fr;

    button {
      height: 40px;

      grid-row: 8;
      grid-column: span 2;
    }

    @media (min-width: 525px) {
      div:nth-child(1) {
        grid-row: 1;
        grid-column: 1;
      }

      div:nth-child(2) {
        grid-row: 2;
        grid-column: 1;
      }

      div:nth-child(3) {
        grid-row: 3;
        grid-column: 1;
      }

      div:nth-child(4) {
        grid-row: 4;
        grid-column: 1;
      }

      div:nth-child(5) {
        grid-row: 5;
        grid-column: 1;
      }

      div:nth-child(6) {
        grid-row: 6;
        grid-column: 1;
      }

      div:nth-child(7) {
        grid-row: 7;
        grid-column: 1;
      }
    }

    @media (max-width: 525px) {
      grid-template-rows: repeat(15, 1fr);
      grid-template-columns: 1fr;

      button {
        height: 40px;

        grid-row: 15;
        grid-column: 1;
      }
    }
  }
`;

export const ToggleSwitchInputContainer = styled.div`
  width: 100%;
  height: 40px;

  padding: 5px 20px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  border-radius: 0.5rem;

  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border_03};

  label:first-child {
    width: 100%;
    text-align: center;
  }
`;
