import styled from "styled-components";

export const OrderRegistrationContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  gap: 10px;
  align-items: center;

  > form {
    max-width: 500px;
    max-height: 300px;

    width: 100%;

    padding: 0px 20px;

    display: flex;
    flex-direction: column;

    align-items: center;

    gap: 10px;

    h1 {
      user-select: none;
    }

    span {
      margin-top: 0.25rem;
      color: #f31;
      font-size: 0.875rem;

      user-select: none;
    }

    button:nth-child(4) {
      border-color: #53ce5d;
      background-color: #53ce5d;
    }
  }

  > div {
    max-width: 500px;

    width: 100%;
    height: 100%;

    padding: 0px 20px;

    display: flex;
    flex-direction: column;

    gap: 10px;
    align-items: center;

    overflow-y: auto;
  }
`;

export const ItemsShoppingCartContainer = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  flex-direction: row;

  align-items: center;

  padding: 5px 10px;

  border-width: 1px;
  border-style: solid;
  border-color: black;
  border-radius: 8px;

  user-select: none;

  &:hover {
    cursor: pointer;
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.color_03};
    background-color: ${({ theme }) => theme.colors.color_01};
  }

  > img {
    border-radius: 5px;
  }

  > div {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: row;

      p {
        width: 100%;
        text-align: center;
      }
    }
  }
`;
