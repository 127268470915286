import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  OrderContainer,
  OrderStatusContainer,
  OrderSummaryContainer,
  ItemsShoppingCartContainer,
} from "./styles";

import Button from "../../Components/Button";

import iconAlertCancel from "../../Assets/alert-error(white).svg";
import iconAlertFinish from "../../Assets/alert-success(white).svg";
import iconAlertSuccess from "../../Assets/alert-success(white).svg";
import iconWhatsApp from "../../Assets/whatsapp.png";

import { FIND_SHOPPINGCART } from "../../Api/Carrinho";
import { IMAGE_PRODUCT } from "../../Api/Produto";

import useFetch from "../../Hook/useFetch";
import { NUMERO_WHATSAPP } from "../../Helper/link";

export const Order = () => {
  const navigate = useNavigate();
  const { id: shoppingCartId } = useParams();

  const { request } = useFetch();

  const [shoppingCart, setShoppingCart] = React.useState(null);
  const [products, setProducts] = React.useState([]);

  const handleMessageWhatsApp = () => {
    const phoneNumber = NUMERO_WHATSAPP;
    const orderLink = `https://seugift.com.br/pedido/${shoppingCartId}`;

    const message = `Olá! Aqui está o meu pedido:\n ${orderLink}`;
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleFindShoppingCart = React.useCallback(async () => {
    if (!shoppingCartId) {
      navigate("/");
    } else {
      const { url, options } = FIND_SHOPPINGCART(shoppingCartId);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        setShoppingCart(response?.carrinho);
        setProducts(response?.carrinho?.itens);
      }
    }
  }, [shoppingCartId, navigate, request]);

  React.useEffect(() => {
    handleFindShoppingCart();
  }, [handleFindShoppingCart]);

  return (
    <OrderContainer>
      <h1>Olá {shoppingCart?.nome},</h1>
      {shoppingCart?.cancelado_em ? (
        <OrderStatusContainer style={{ backgroundColor: "lightsalmon" }}>
          <img
            width={35}
            height={35}
            src={iconAlertCancel}
            alt="Icone Alert Cancel"
          />
          <strong>Pedido Cancelado</strong>
        </OrderStatusContainer>
      ) : shoppingCart?.finalizado_em ? (
        <OrderStatusContainer style={{ backgroundColor: "lightblue" }}>
          <img
            width={35}
            height={35}
            src={iconAlertFinish}
            alt="Icone Alert Finish"
          />
          <strong>Pedido Finalizado</strong>
        </OrderStatusContainer>
      ) : (
        <OrderStatusContainer style={{ backgroundColor: "lightgreen" }}>
          <img
            width={35}
            height={35}
            src={iconAlertSuccess}
            alt="Icone Alert Success"
          />
          <strong>Pedido feito com Sucesso</strong>
        </OrderStatusContainer>
      )}
      <OrderSummaryContainer>
        {products?.length > 0 && products[0]?.id !== null ? (
          products.map((p) => {
            return (
              <ItemsShoppingCartContainer
                key={p?.id}
                onClick={() => navigate(`/produto/${p?.id}`)}
              >
                <img
                  width={50}
                  height={50}
                  alt="Imagem Produto"
                  src={IMAGE_PRODUCT(p?.imagem_produto)}
                />
                <div>
                  <div>
                    <p>{p?.nome}</p>
                  </div>
                  <div>
                    <p>
                      Quant. {products.filter((i) => i?.id === p?.id).length}
                    </p>
                    <p>R$ {p?.preco}</p>
                  </div>
                </div>
              </ItemsShoppingCartContainer>
            );
          })
        ) : (
          <h3>Nenhum produto encontrado</h3>
        )}
      </OrderSummaryContainer>
      <Button type="button" onClick={handleMessageWhatsApp}>
        Conversar por WhatsApp
        <img width={20} height={20} src={iconWhatsApp} alt="Icone WhatsApp" />
      </Button>
      <Button type="button" onClick={() => navigate("/")}>
        Voltar para o Catalogo
      </Button>
    </OrderContainer>
  );
};
