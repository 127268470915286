import React from "react";

import { CategoryDataContainer } from "./styles";

import iconUpdate from "../../../../../Assets/update(white).svg";
import iconDelete from "../../../../../Assets/delete(white).svg";

import ModalCreate from "../ModalCreate";
import ModalUpdate from "../ModalUpdate";

import Table from "../../../../../Components/Table";
import Button from "../../../../../Components/Button";
import ToggleSwitch from "../../../../../Components/ToogleSwitch";

import {
  FIND_CATEGORY,
  LIST_CATEGORIES,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "../../../../../Api/Categoria";

import useFetch from "../../../../../Hook/useFetch";

import useUser from "../../../../../Context/User/useUser";

const CategoryData = () => {
  const { request } = useFetch();

  const [categories, setCategories] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [categoryData, setCategoryData] = React.useState(null);

  const [isOpenModalCreate, setIsOpenModalCreate] = React.useState(false);
  const [isOpenModalUpdate, setIsOpenModalUpdate] = React.useState(false);

  const { featchUserToken } = useUser();

  React.useEffect(() => {
    featchUserToken();
  }, [featchUserToken]);

  const handleListCategories = React.useCallback(async () => {
    try {
      setLoading(true);
      setError("");

      const isVisible = null;
      const token = localStorage.getItem("token");

      const { url, options } = LIST_CATEGORIES(isVisible, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        setCategories(response.categorias);
      }
    } catch (err) {
      setError("Falha ao listar as categorias. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, [request]);

  React.useEffect(() => {
    handleListCategories();
  }, [handleListCategories]);

  const handleFindCategory = React.useCallback(
    async (id) => {
      if (!id) return;

      try {
        const token = window.localStorage.getItem("token");

        const { url, options } = FIND_CATEGORY(id, token);

        const { response } = await request(url, options);

        if (response.ok === "true") {
          setCategoryData(response.categoria);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [request]
  );

  const handleUpdateCategory = async (id, highlights, visible) => {
    if (!id) return;

    try {
      const token = window.localStorage.getItem("token");

      let body;
      if (highlights === 1 || highlights === 0) {
        body = { destaque: highlights };
      } else if (visible === 1 || visible === 0) {
        body = { visivel: visible };
      } else {
        return;
      }

      const { url, options } = UPDATE_CATEGORY(id, body, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        let status;

        if (highlights === 1 || highlights === 0) {
          if (highlights === 1) {
            status = 0;
          } else {
            status = 1;
          }

          setCategories((prevCategory) =>
            prevCategory.map((category) =>
              category.id === id ? { ...category, destaque: status } : category
            )
          );
        } else if (visible === 1 || visible === 0) {
          if (visible === 1) {
            status = 0;
          } else {
            status = 1;
          }

          setCategories((prevCategory) =>
            prevCategory.map((category) =>
              category.id === id ? { ...category, visivel: status } : category
            )
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteCategory = async (id) => {
    if (!id) return;

    try {
      const confirmed = window.confirm(
        "Tem certeza de que deseja excluir esta categoria?"
      );

      if (!confirmed) {
        return;
      }

      const token = window.localStorage.getItem("token");

      const { url, options } = DELETE_CATEGORY(id, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        await handleListCategories();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CategoryDataContainer>
      <div>
        <Button type="button" onClick={() => setIsOpenModalCreate(true)}>
          Novo Categoria
        </Button>
      </div>
      <Table error={error} loading={loading}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Ordem</th>
            <th>Destaque</th>
            <th>Visualização</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={index}>
              <td>{category?.nome}</td>
              <td>{category?.ordem ? category?.ordem : "N/A"}</td>
              <td>
                <ToggleSwitch
                  isActive={category?.destaque === 1 ? true : false}
                  onToggle={() => {
                    handleUpdateCategory(category?.id, category?.destaque);
                  }}
                />
              </td>
              <td>
                <ToggleSwitch
                  isActive={category?.visivel === 1 ? true : false}
                  onToggle={() => {
                    handleUpdateCategory(category?.id, "", category?.visivel);
                  }}
                />
              </td>
              <td>
                <Button
                  type="button"
                  title="Editar categoria"
                  onClick={async () => {
                    const result = await handleFindCategory(category?.id);
                    if (result === true) setIsOpenModalUpdate(true);
                  }}
                >
                  <img
                    width="26"
                    height="26"
                    src={iconUpdate}
                    alt="Editar categoria"
                  />
                </Button>
                <Button
                  type="button"
                  title="Excluir categoria"
                  onClick={() => handleDeleteCategory(category?.id)}
                >
                  <img
                    width="26"
                    height="26"
                    src={iconDelete}
                    alt="Excluir categoria"
                  />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ModalCreate
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        handleListCategories={handleListCategories}
      />
      <ModalUpdate
        isOpen={isOpenModalUpdate}
        setIsOpen={setIsOpenModalUpdate}
        categoryData={categoryData}
        setCategoryData={setCategoryData}
        handleListCategories={handleListCategories}
      />
    </CategoryDataContainer>
  );
};

export default CategoryData;
