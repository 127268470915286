import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;

  width: 100%;

  input {
    width: 100%;
    height: 40px !important;

    padding: 0.6875rem;
    font-size: 1rem;

    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.border_03};

    background: none;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &:focus,
    &.has-value {
      outline: none;

      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.border_02};
    }

    &:focus ~ label,
    &.has-value ~ label {
      padding: 0 0.5rem;
      transform: translateY(-50%) scale(0.8);
      color: ${({ theme }) => theme.colors.text_02};
      background-color: ${({ theme }) => theme.colors.color_01};
    }
  }

  button {
    height: 25px !important;

    padding: 0px 5px;

    position: absolute;
    top: 20px;
    right: 10px;

    transform: translateY(-50%);

    color: black;
    font-size: 0.85rem;

    cursor: pointer;
  }

  label {
    width: fit-content;

    padding: 0 5px;

    position: absolute;
    top: -0.05rem;
    left: 0.5rem;

    pointer-events: none;

    font-size: 1rem;

    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.background_01};

    transform: translateY(0.75rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  span {
    margin-top: 0.25rem;
    color: #f31;
    font-size: 0.875rem;
  }
`;
