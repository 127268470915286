import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AppContainer } from "./styles.js";

import { Header } from "../Components/Header";
import { ShowAlert } from "../Components/ShowAlert";

import { Catalog } from "../Page/Catalog";
import { Product } from "../Page/Product";
import { Order } from "../Page/Order";
import { OrderRegistration } from "../Page/OrderRegistration";
import { Authentication } from "../Page/Authentication";

import { StoreData } from "../Page/StoreData";

import { UserProvider } from "../Context/User/UserContext";
import { AlertProvider } from "../Context/Alert/AlertContext.js";
import { ShoppingCartProvider } from "../Context/ShoppingCart/ShoppingCartContext";

import useUser from "../Context/User/useUser.js";

import Loading from "../Components/Loading/index.js";
import { UserRegistration } from "../Page/UserRegistration/index.js";

const ProtectedRouteAdim = ({ children }) => {
  const { loginUser } = useUser();

  if (loginUser === null) {
    return <Loading />;
  } else if (loginUser === true) {
    return children;
  } else if (loginUser === false) {
    return <Navigate to="/autenticação" />;
  }
};

const App = () => {
  return (
    <AppContainer>
      <BrowserRouter>
        <AlertProvider>
          <UserProvider>
            <ShoppingCartProvider>
              <ShowAlert />
              <Header />
              <Routes>
                <Route path="/" element={<Catalog />} />

                <Route path="/pedido/:id" element={<Order />} />
                <Route path="/produto/:id" element={<Product />} />

                <Route
                  path="/cadastro-pedido"
                  element={<OrderRegistration />}
                />
                <Route
                  path="/cadastro-usuario"
                  element={<UserRegistration />}
                />

                <Route path="/autenticação" element={<Authentication />} />

                <Route
                  path="/dados-loja"
                  element={
                    <ProtectedRouteAdim>
                      <StoreData />
                    </ProtectedRouteAdim>
                  }
                />
              </Routes>
            </ShoppingCartProvider>
          </UserProvider>
        </AlertProvider>
      </BrowserRouter>
    </AppContainer>
  );
};

export default App;
