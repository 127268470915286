import React from "react";

import Modal from "../../../../../Components/Modal";
import Input from "../../../../../Components/Input";
import Button from "../../../../../Components/Button";
import Textarea from "../../../../../Components/Textarea";

import { UPDATE_PRODUCT } from "../../../../../Api/Produto";

import useForm from "../../../../../Hook/useForm";
import useFetch from "../../../../../Hook/useFetch";

const ModalUpdate = ({
  isOpen,
  setIsOpen,
  productData,
  setProductData,
  handleListProducts,
}) => {
  const { loading, request } = useFetch();

  const [error, setError] = React.useState(null);

  const owner = useForm(true);
  const name = useForm(true);
  const price = useForm("price");
  const description = useForm(false);

  const [currentStock, setCurrentStock] = React.useState(1);
  const [minimumStock, setMinimumStock] = React.useState(1);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    setError("");

    setProductData(null);
    owner.setValue("");
    name.setValue("");
    price.setValue("");
    description.setValue("");
    setCurrentStock("1");
    setMinimumStock("1");
  }, [setIsOpen, setError, setProductData, owner, name, price, description]);

  React.useEffect(() => {
    if (productData) {
      owner.setValue(productData.criador);
      name.setValue(productData.nome);
      price.setValue(productData.preco.replace(".", ","));
      description.setValue(productData.descricao);
    } else {
      onClose();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [productData]);

  const handleUpdateProduct = async () => {
    try {
      setError("");

      if (
        owner.validation() &&
        name.validation() &&
        price.validation() &&
        currentStock > 0 &&
        minimumStock > 0
      ) {
        const token = window.localStorage.getItem("token");

        const formData = new FormData();

        formData.append("criador", owner.value);
        formData.append("nome", name.value);
        formData.append("preco", price.value.replace(",", "."));
        formData.append("descricao", description.value);
        formData.append("estoque_atual", currentStock);
        formData.append("estoque_minimo", minimumStock);

        const { url, options } = UPDATE_PRODUCT(
          productData.id,
          formData,
          token
        );

        const { response } = await request(url, options);

        if (response.ok === "true") {
          await handleListProducts();
          onClose();
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (err) {
      setError("Falha na atualização. Tente novamente mais tarde");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleUpdateProduct}>
      <h1>Atualização de Produto</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Dono do Produto"
        {...owner}
        label="Proprietário"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Nome do Produto"
        {...name}
        label="Nome"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Preço do Produto"
        {...price}
        label="Preço R$"
      />
      <Textarea
        type="text"
        disabled={loading}
        title="Descrição do Produto"
        {...description}
        label="Descrição"
      />
      <Button
        type="submit"
        disabled={loading}
        onClick={() => handleUpdateProduct()}
      >
        Atualizar Produto
      </Button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </Modal>
  );
};

export default ModalUpdate;
