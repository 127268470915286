import React from "react";

import { AdsDataContainer } from "./styles";

import iconUpdate from "../../../../../Assets/update(white).svg";
import iconDelete from "../../../../../Assets/delete(white).svg";

import ModalCreate from "../ModalCreate";
import ModalUpdate from "../ModalUpdate";

import Table from "../../../../../Components/Table";
import Button from "../../../../../Components/Button";
import ToggleSwitch from "../../../../../Components/ToogleSwitch";

import {
  FIND_AD,
  LIST_ADS,
  UPDATE_AD,
  DELETE_AD,
} from "../../../../../Api/Anuncio";

import useUser from "../../../../../Context/User/useUser";

import useFetch from "../../../../../Hook/useFetch";

const AdsData = () => {
  const { request } = useFetch();

  const [ads, setAds] = React.useState([]);

  const [adData, setAdData] = React.useState(null);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [isOpenModalCreate, setIsOpenModalCreate] = React.useState(false);
  const [isOpenModalUpdate, setIsOpenModalUpdate] = React.useState(false);

  const { featchUserToken } = useUser();

  React.useEffect(() => {
    featchUserToken();
  }, [featchUserToken]);

  const handleListAds = React.useCallback(async () => {
    try {
      setLoading(true);
      setError("");

      const title = "";
      const token = localStorage.getItem("token");

      const { url, options } = LIST_ADS(title, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        setAds(response.anuncios);
      }
    } catch (err) {
      setError("Falha ao listar os anúncios. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, [request]);

  React.useEffect(() => {
    handleListAds();
  }, [handleListAds]);

  const handleFindAd = React.useCallback(
    async (id) => {
      if (!id) return;

      try {
        const token = window.localStorage.getItem("token");

        const { url, options } = FIND_AD(id, token);

        const { response } = await request(url, options);

        if (response.ok === "true") {
          setAdData(response.anuncio);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [request]
  );

  const handleUpdateAd = async (adId, visible) => {
    if (!adId) return;

    try {
      const token = window.localStorage.getItem("token");

      const body = { visivel: JSON.stringify(visible) };

      const { url, options } = UPDATE_AD(adId, body, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        let status = visible === 1 ? 0 : 1;
        setAds((prevAds) =>
          prevAds.map((ad) =>
            ad.id === adId ? { ...ad, visivel: status } : ad
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAd = async (id) => {
    if (!id) return;

    try {
      const confirmed = window.confirm(
        "Tem certeza de que deseja excluir este anúncio?"
      );

      if (!confirmed) {
        return;
      }

      const token = window.localStorage.getItem("token");

      const { url, options } = DELETE_AD(id, token);

      const { response } = await request(url, options);

      if (response.ok === "true") {
        await handleListAds();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AdsDataContainer>
      <div>
        <Button type="button" onClick={() => setIsOpenModalCreate(true)}>
          Novo Anúncio
        </Button>
      </div>
      <Table error={error} loading={loading}>
        <thead>
          <tr>
            <th>Titulo</th>
            <th>Data Inicio</th>
            <th>Data Termino</th>
            <th>Visualização</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {ads.map((ad, index) => (
            <tr key={index}>
              <td>{ad?.titulo}</td>
              <td>
                {ad?.data_inicio ? `${ad?.data_inicio_formatada}` : "N/D"}
              </td>
              <td>
                {ad?.data_termino ? `${ad?.data_termino_formatada}` : "N/D"}
              </td>
              <td>
                <ToggleSwitch
                  isActive={ad?.visivel === 1 ? true : false}
                  onToggle={() => {
                    handleUpdateAd(ad?.id, ad?.visivel);
                  }}
                />
              </td>
              <td>
                <Button
                  type="button"
                  title="Atualizar produto"
                  onClick={async () => {
                    const result = await handleFindAd(ad?.id);
                    if (result === true) setIsOpenModalUpdate(true);
                  }}
                >
                  <img
                    width="26"
                    height="26"
                    src={iconUpdate}
                    alt="Atualizar Produto"
                  />
                </Button>
                <Button
                  type="button"
                  title="Excluir produto"
                  onClick={() => handleDeleteAd(ad?.id)}
                >
                  <img
                    width="26"
                    height="26"
                    src={iconDelete}
                    alt="Apagar Produto"
                  />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ModalCreate
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        handleListAds={handleListAds}
      />
      <ModalUpdate
        isOpen={isOpenModalUpdate}
        setIsOpen={setIsOpenModalUpdate}
        adData={adData}
        setAdData={setAdData}
        handleFindAd={handleFindAd}
        handleListAds={handleListAds}
      />
    </AdsDataContainer>
  );
};

export default AdsData;
