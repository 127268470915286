import React from "react";
import PropTypes from "prop-types";

import { FIND_USER } from "../../Api/Usuario";

import useFetch from "../../Hook/useFetch";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [data, setData] = React.useState(null);
  const [errorUser, setErrorUser] = React.useState(null);
  const [loginUser, setLoginUser] = React.useState(null);

  const { request } = useFetch();

  const userLogout = React.useCallback(() => {
    setData(null);
    setErrorUser(null);
    setLoginUser(false);
    window.localStorage.removeItem("token");
  }, [setData, setErrorUser, setLoginUser]);

  const featchUserToken = React.useCallback(async () => {
    const token = window.localStorage.getItem("token");

    if (!token) {
      setData(null);
      setErrorUser(null);
      setLoginUser(false);
      return false;
    }

    const { url, options } = FIND_USER(token);
    const { response } = await request(url, options);

    if (response.ok === "true") {
      window.localStorage.setItem("token", response.token);

      setData(response.usuario);
      setLoginUser(true);

      return true;
    } else {
      userLogout();
      return false;
    }
  }, [request, userLogout]);

  React.useEffect(() => {
    if (loginUser) return;
    featchUserToken();
  }, [loginUser, featchUserToken]);

  return (
    <UserContext.Provider
      value={{
        data,
        setData,
        errorUser,
        setErrorUser,
        loginUser,
        setLoginUser,
        userLogout,
        featchUserToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserContext };
