import React from "react";

import {
  CatalogContainer,
  CatalogSubContainer,
  DetailFilterContainer,
  ADModalTitle,
  ADModalDescription,
  ListCardProductContainer,
} from "./styles";

import SideBarFilter from "./Components/SideBarFilter";

import Modal from "../../Components/Modal";
import Loading from "../../Components/Loading";
import Carousel from "../../Components/Carousel";
import CardProduct from "../../Components/CardProduct";
import SideBarShoppingCart from "../../Components/SideBarShoppingCart";

import { CATALOG_ADS } from "../../Api/Anuncio";
import { CATALOG_PRODUCTS } from "../../Api/Produto";

export const Catalog = () => {
  const [ad, setAd] = React.useState(null);
  const [catalog, setCatalog] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [isOpenSideBarFilter, setIsOpenSideBarFilter] = React.useState(true);
  const [categoryId, setCategoryId] = React.useState(3);
  const [nameProduct, setNameProduct] = React.useState("");
  const [selectCategory, setSelectCategory] = React.useState("Cestas");

  const [isOpenADModal, setIsOpenADModal] = React.useState(false);

  const handleCatalogAds = React.useCallback(async () => {
    try {
      setError("");
      setLoading(true);

      const { url, options } = CATALOG_ADS("1");

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        console.log(responseJson.anuncio);
        setAd(responseJson.anuncio);
      }
    } catch (err) {
      setError("Falha no servidor. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCatalogProducts = React.useCallback(async () => {
    try {
      setError("");
      setLoading(true);

      const params = {
        visible: "1",
        categoryId: nameProduct ? null : categoryId,
        nameProduct,
      };

      const { url, options } = CATALOG_PRODUCTS(params);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setCatalog(responseJson.produtos);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Falha no servidor. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }, [categoryId, nameProduct]);

  React.useEffect(() => {
    handleCatalogAds();
    handleCatalogProducts();
  }, [handleCatalogAds, handleCatalogProducts]);

  const handleRenderCatalogContent = () => {
    if (loading === true) {
      return <Loading />;
    }

    if (error) {
      return <strong>{error}</strong>;
    }

    if (catalog.length > 0) {
      return catalog.map((product) => (
        <CardProduct key={product.id} product={product} />
      ));
    }
  };

  React.useEffect(() => {}, []);

  return (
    <CatalogContainer>
      {ad?.imagens && ad?.imagens.length > 0 ? (
        <Carousel
          className="carousel"
          images={ad?.imagens}
          onClick={() => setIsOpenADModal(true)}
        />
      ) : null}
      <DetailFilterContainer
        onClick={() => setIsOpenSideBarFilter(!isOpenSideBarFilter)}
      >
        <p>Pesquisando Produto</p>
        {!nameProduct ? (
          <>
            <p>- Categoria:</p>
            <strong>{!nameProduct && selectCategory}</strong>
          </>
        ) : (
          <>
            <p>- Nome:</p>
            <strong>{nameProduct}</strong>
          </>
        )}
      </DetailFilterContainer>
      <CatalogSubContainer>
        <SideBarFilter
          isOpen={isOpenSideBarFilter}
          setIsOpen={setIsOpenSideBarFilter}
          error={error}
          setError={setError}
          loading={loading}
          setLoading={setLoading}
          nameProduct={nameProduct}
          setNameProduct={setNameProduct}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          setSelectCategory={setSelectCategory}
        />
        <ListCardProductContainer>
          {handleRenderCatalogContent()}
        </ListCardProductContainer>
      </CatalogSubContainer>
      <Modal isOpen={isOpenADModal} onClose={() => setIsOpenADModal(false)}>
        <ADModalTitle>{ad?.titulo}</ADModalTitle>
        <Carousel images={ad?.imagens} />
        <ADModalDescription>{ad?.descricao}</ADModalDescription>
      </Modal>
      <SideBarShoppingCart />
    </CatalogContainer>
  );
};
