import styled from "styled-components";

const shouldForwardProp = (prop) => !["isOpen"].includes(prop);

export const SideBarShoppingCartContainer = styled.div.withConfig({
  shouldForwardProp,
})`
  width: 300px;
  height: 100vh;

  padding: 5px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  right: 0;

  color: ${({ theme }) => theme.colors.color_02};
  background-color: white;

  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);

  transition: transform 0.3s ease;
  transform: translateX(${(props) => (props.isOpen ? "0" : "100%")});

  z-index: 1000;
`;

export const ButtonShoppingCart = styled.button`
  padding: 5px;

  position: fixed;
  right: 10px;
  top: 67.5px;

  border-radius: 50%;

  span {
    width: 25px;
    height: 25px;

    padding-bottom: 2px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -5px;
    right: -5px;

    border-radius: 50%;
    background-color: red;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 999;
`;

export const HeaderSideBar = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const BodySideBar = styled.div`
  width: 100%;
  max-height: 80%;

  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 5px;

  overflow-y: auto;

  h2 {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.25rem;
    text-align: center;

    transition: 0.2s;
  }

  .empty-shopping-cart-error {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.color_03};
  }
`;

export const ItemsShoppingCart = styled.div`
  width: 100%;
  height: 90px;

  padding: 5px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  border-radius: 10px;

  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.color_01};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background_02};
  }

  div:nth-child(1) {
    width: 100%;
    height: 60px;

    padding-left: 5px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-around;

    > img {
      cursor: pointer;
      border-radius: 5px;
    }

    > div {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: space-around;

      text-align: center;

      > p {
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  div:nth-child(2) {
    width: 100%;
    height: 20px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    button {
      width: 40px !important;
      height: 20px !important;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const FooterSideBar = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 5px;

  .whatsapp {
    display: flex;
    flex-direction: row;

    gap: 10px;
    align-items: center;
    justify-content: center;

    border-color: #5bbf5f;
    background-color: #53ce5d;

    &:hover {
      background-color: #5bbf5f;
    }
  }
`;
