import React from "react";

import Modal from "../../../../../Components/Modal";
import Input from "../../../../../Components/Input";
import Button from "../../../../../Components/Button";

import { UPDATE_CATEGORY } from "../../../../../Api/Categoria";

import useForm from "../../../../../Hook/useForm";
import useFetch from "../../../../../Hook/useFetch";

const ModalUpdate = ({
  isOpen,
  setIsOpen,
  categoryData,
  setCategoryData,
  handleListCategories,
}) => {
  const { loading, request } = useFetch();

  const [error, setError] = React.useState(null);

  const name = useForm(true);
  const order = useForm(true);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    setError("");
    setCategoryData(null);
    name.setValue("");
    order.setValue("");
  }, [setIsOpen, setError, setCategoryData, name, order]);

  React.useEffect(() => {
    if (categoryData) {
      name.setValue(categoryData.nome);
      order.setValue(categoryData.ordem);
    } else {
      onClose();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [categoryData]);

  const handleUpdateCategory = async () => {
    try {
      setError("");

      if (name.validation() && order.validation()) {
        const token = window.localStorage.getItem("token");

        const body = {
          nome: name.value,
          ordem: order.value,
        };

        const { url, options } = UPDATE_CATEGORY(categoryData.id, body, token);

        const { response } = await request(url, options);

        if (response.ok === "true") {
          await handleListCategories();
          onClose();
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (error) {
      setError("Falha no cadastro. Tente novamente mais tarde");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleUpdateCategory}>
      <h1>Atualização de Categoria</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Nome da Categoria"
        {...name}
        label="Nome"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Ordem da Categoria"
        {...order}
        label="Ordem"
      />
      <Button type="submit" disabled={loading}>
        Salvar
      </Button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </Modal>
  );
};

export default ModalUpdate;
